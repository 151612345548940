:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #26c281;
  --turquoise: #36D7B7;
  --white: #ffffff;
  --light-grey: #bdc3c7;
  --dark-grey: #2b2b2b;
  --black: #000000;
}
